
import { AxiosError } from 'axios';
import { ActionType, createAsyncAction } from 'typesafe-actions';
import AsyncUtils from '../../util/AsyncUtils';

export const LOGIN = 'login/LOGIN';
export const LOGIN_SUCCESS = 'login/LOGIN_SUCCESS';
export const LOGIN_FAIL = 'login/LOGIN_FAIL';

export interface LoginActionParam {
  id: string;
  pw: string;
  url: string;
}

interface LoginResponse {
  url: string;
}

export const loginAction = createAsyncAction(
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL)<LoginActionParam, LoginResponse, AxiosError>();

export const actions = { login: loginAction };

export type LoginAction = ActionType<typeof actions>;

export const LoginCaller = new AsyncUtils<{}, typeof actions>();

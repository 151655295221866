import { asyncHelper } from './../../util/AsyncUtils';
import { IndexState } from './IndexReducer';
import { ThunkAction } from 'redux-thunk';
import { IndexInitAction, indexInitAction } from './IndexAction';
import CompanyService from '../company/CompanyService';
import ProjectGetService from '../project/ProjectGetService';


export const getUserProjectAndCompany = () : ThunkAction<void, IndexState, null, IndexInitAction> => {
  const companyPromise = new CompanyService().getMyCompany();
  const projectPromise = new ProjectGetService().getProjects();

  return asyncHelper(Promise.all([companyPromise, projectPromise])
    .then(([c, p]) => {
      return { company: c, project: p };
    }), indexInitAction);
};

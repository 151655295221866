import { serverUrl } from '../../util/SvAPI';
import { post, put } from '../common/HttpCaller';
import Project from './Project';

export enum ProjectType {
  MOVIE = 'movie',
  SERIES = 'series',
}

export interface CreateProject {
  name: string;
  thumbnail?: string;
  description?: string;
  plan?: string;
  ep?: string;
  actor?: string;
  writer?: string;
  producer?: string;
  director?: string;
}

export default class ProjectModService {
  addProject = async (param: CreateProject) => {
    const response: number = await post(`${serverUrl}/project`, { body: param });
    return response;
  };

  updateProject = async (project: Project, updateParam: CreateProject) => {
    const updateBody: Project = { ...project, ...updateParam };
    console.log('update project', project, 'with', updateParam);
    const response: Project = await put(`${serverUrl}/project/${project.projectNo}`, { body: updateBody });
    return response;
  };
}
